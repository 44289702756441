import languagesData from "@/assets/json/languages.json";
export default {
  data: () => ({
    authors: [],
    donors: [],
    vendors: [],
    publishers: [],

    racks: [],
    sectors: [],
    subjects: [],
    categories: [],

    showModal: false,
    reusecomponent: false,
    show_publisher: false,
    show_vendor: false,
    show_donor: false,
    show_author: false,

    languagesData,
    language:[],

  }),
  methods: {
    getAuthors(param = true) {
      if(this.authors.length <= 0){
        this.$rest.get("/library/api/author?rowsPerPage=1000").then(({ data }) => {
          var authors_data = data.data;
          this.authors = authors_data.map((author) => {
            return { text: author.name, value: author.id };
          });
        });
        if(param == true){
            this.show_author = false;
            this.reusecomponent = false;
            this.showModal = false;
        }
      }
    },
    getDonor(param = true) {
      this.donors = [];
      this.$rest.get("/library/api/donor?rowsPerPage=1000").then(({ data }) => {
        var donors_data = data.data;
        this.donors = donors_data.map((donor) => {
          return { text: donor.name, value: donor.id };
        });
      });
      if(param = true){
          this.show_donor = false;
          this.reusecomponent = false;
          this.showModal = false;
      }
    },
    getVendor(param = true) {
      this.$rest.get("/library/api/vendor?rowsPerPage=1000").then(({ data }) => {
        var vendor_data = data.data;
        this.vendors = vendor_data.map((vendor) => {
          return { text: vendor.name, value: vendor.id };
        });
      });
      if(param == true){
          this.show_vendor = false;
          this.reusecomponent = false;
          this.showModal = false;
      }
    },
    getPublisher(param = true) {
      this.$rest.get("/library/api/publisher?rowsPerPage=1000").then(({ data }) => {
        var publisher_data = data.data;
        this.publishers = publisher_data.map((publisher) => {
          return { text: publisher.name, value: publisher.id };
        });
      });
      if(param === true){
          this.show_publisher = false;
          this.reusecomponent = false;
          this.showModal = false;
      }
    },
    getRack() {
      this.$rest.get("/library/api/rack?rowsPerPage=1000").then(({ data }) => {
        var rack_data = data.data;
        this.racks = rack_data.map((rack) => {
          return { text: rack.name, value: rack.id };
        });
      });
    },
    getSector() {
      this.$rest.get("/library/api/sector?rowsPerPage=1000").then(({ data }) => {
        var sector_data = data.data;
        this.sectors = sector_data.map((sector) => {
          return { text: sector.name, value: sector.id };
        });
      });
    },
   
    getCategories() {
      this.$rest.get("/library/api/category?rowsPerPage=1000").then(({ data }) => {
        var category_data = data.data;
        this.categories = category_data.map((category) => {
          return { text: category.name, value: category.id };
        });
      });
    },
    getSubject() {
      this.$rest.get("/library/api/subject").then(({ data }) => {
        var subject_data = data.data;
        this.subjects = subject_data.map((subject) => {
          return { text: subject.name, value: subject.id };
        });
      });
    },
    getLanguages() {
        this.languagesData.forEach((obj) => {
          this.language.push(obj.name);
        });
    },
  },
};
